<template>
	<div class="sc-card-product explode style2 mg-bt bd-noresult-nft-container" :class="{'two-per-line': nft_per_line == 'two','three-per-line': nft_per_line == 'three', 'four-per-line': nft_per_line == 'four'}">
		<div class="card-media">
			<div class="bd-noresult-text center" v-html="text"></div>
			<img src="/assets/images/bluediamonds/bluediamond-nft-card.png" alt="Image" style="aspect-ratio: 1 / 1;">
		</div>
		<div class="card-title">
			<h5 style="display: block;">
				<br/>
			</h5>
		</div>
		<div class="card-bottom style-explode">
			<div>
				<span class="badge badge-success bd-noresult-buy">&nbsp;</span>
				<span class="bd-noresult-makeoffer bd-desktop">&nbsp;</span>
			</div>
			<i class="fas fa-heart"></i>
		</div>
	</div>
</template>

<script>



export default {
	name: 'NoResultsNft',
	components: {

	},
	props: {

		nft_per_line: {
			type: String,
			default: 'four'
		},
    text: {
      type: String,
      default: 'No NFT listed<br/>at this moment<br/><h4>Be the first!</h4>',
    },
	}
}
</script>

