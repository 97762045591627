<template>
	<div class="sc-card-product explode style2 mg-bt bd-nftcard" :class="{'two-per-line': nft_per_line == 'two','three-per-line': nft_per_line == 'three', 'four-per-line': nft_per_line == 'four', 'bd-nft-listed': nft.listed == 1}">
		<div class="card-media">
			<router-link :to="'/nft/'+nft.mint_address">
        <img v-show="load_picture" :src="nft.picture" alt="Image" style="aspect-ratio: 1 / 1; object-fit: cover;" @load="load_picture = true">
        <img v-show="!load_picture" src="/assets/images/bluediamonds/loader-light.gif" alt="Image" style="aspect-ratio: 1 / 1;" class="bd-light" />
        <img v-show="!load_picture" src="/assets/images/bluediamonds/loader-dark.gif" alt="Image" style="aspect-ratio: 1 / 1;" class="bd-dark" />
      </router-link>
		</div>
		<div class="card-title">
			<h5 style="display: block; font-size: 15px;">
				<router-link :to="'/nft/'+nft.mint_address">
					Rank: {{ nft.ranking }}
					<span class="fa fa-gem" style="color: var(--primary); margin-right: 5px; font-size: 15px;" v-if="nft.diamonds >= 1"></span>
					<span class="fa fa-gem" style="color: var(--primary); margin-right: 5px; font-size: 15px;" v-if="nft.diamonds >= 2"></span>
				</router-link>
			</h5>
			<span style="font-size: 15px; font-weight: 600" v-show="nft.listed !== null">◎ {{ nft.price }}</span>
			<span style="font-size: 15px; font-weight: 600" v-show="nft.listed === null">Not listed</span>
		</div>
		<div class="meta-info">
			<div class="author">
				<div class="info">
					<!-- <span class="bd-nftcard-text-light">{{ nft.collection_name }}</span> -->
					<span class="bd-nftcard-text-light">{{ nft.name }}</span>
				</div>
			</div>
			<div class="info">
				<span style="font-size: 13px;"  class="bd-nftcard-text-light" v-if="nft.highest_offer !== null">Offer: ◎ {{ nft.highest_offer }}</span>
				<span style="font-size: 13px;"  class="bd-nftcard-text-light" v-else-if="nft.last_price !== null">Last: ◎ {{ nft.last_price }}</span>
			</div>
		</div>
		<div class="card-bottom style-explode bd-desktop" v-show="context != 'my_account_my_nft'">
			<div>
				<!-- buy -->
				<span class="badge badge-success bd-nftcard-buy" v-show="nft.listed !== null && $root.connected === 1" @click="$root.buy_nft(nft)">Buy</span>
				<span class="badge badge-success bd-nftcard-buy" v-show="nft.listed !== null && $root.connected !== 1" @click="$root.$emit('show_modal',['connect_wallet',true])">Buy</span>
				<span v-show="nft.listed === null"></span>
				
				<!-- make offer -->
				<span class="bd-nftcard-makeoffer" @click="$root.$children[0].$data.modal.make_offer = {show_modal:true,nft:nft,floor_price:nft.collection_floor_price}" v-if="$root.connected === 1 && nft.listed === 1">Make offer</span>
				<span class="bd-nftcard-makeoffer" v-show="$root.connected !== 1 && nft.listed === 1" @click="$root.$emit('show_modal',['connect_wallet',true])">Make offer</span>
			</div>
			<LikeButtonNft :nft="nft" :collection_page="true"></LikeButtonNft>
			
		</div>
		<div class="card-bottom style-explode bd-desktop" v-show="context == 'my_account_my_nft'">
			<div v-if="loading_action === false">
				<!-- list -->
				<span class="badge badge-success bd-nftcard-buy" v-show="nft.listed === null && nft.listing_possible !== false" @click="$root.$children[0].$data.modal.list_nft = {show_modal : true,nft:nft}">List</span>
				<span class="badge badge-success bd-nftcard-buy" v-show="nft.listed === null && nft.listing_possible === false">Coming soon</span>
				
				<!-- cancel -->
				<span class="bd-nftcard-makeoffer bd-nftcard-cancel" v-show="nft.listed == 1" @click="$root.$children[0].$data.modal.list_cancel_nft = {show_modal : true,nft:nft}">Cancel</span>
			</div>
      <div v-else>
        <span class="bd-nftcard-makeoffer bd-nftcard-cancel">
          <img style="width:20%" src="/assets/images/loading.svg"/>
        </span>
      </div>
			
		</div>
	</div>
</template>

<script>

import axios from 'axios';
import LikeButtonNft from '@/components/LikeButtonNft.vue';

let config_axios = {
  headers: {
    'Content-Type': 'application/json;',
  }
}

import toastr from 'toastr';

import {buy_nft_program} from '@/libs/rustProgram';

export default {
  name: 'NftCard',
  components: {
		LikeButtonNft,
  },
  props: {
    nft: {},
    context: {
      type: String,
      default: ''
    },
    nft_per_line: {
      type: String,
      default: 'four'
    },
	},
  data:function(){
      return{
        listing : {
					price: 0,
				},
        show_modal_make_offer : false,
        show_modal_list : false,
        load_picture: false,
        loading_action: false,
      }
  },
  mounted:function(){
    var component = this;

    component.$root.$on('loading_action_on_nft',function(args) {

      if(args.nft_id === component.nft.id) {
        component.loading_action = args.loading;
        component.$forceUpdate();
      }
    });
  },

  watch:{
    'nft.id': function(){
      this.load_picture = false;
    }
  }
}
</script>

