<template>
  <div>
    <div v-for="diamond in [0,1,2]" :key="diamond">
      <label>
        <template v-if="diamond == 0">
          Common
        </template>
        <template v-else>
          <div style="display: block;">
			<span v-show="diamond == 1">Rare</span>
			<span v-show="diamond == 2">Legendary</span>
            <span v-for="number_of_diamond in diamond" :key="number_of_diamond" class="fa fa-gem" style="color: var(--primary); margin-left: 5px;"></span>
          </div>
        </template>
        <input type="checkbox" v-model="filters.diamonds[diamond]" @change="$parent[reload_function]()">
        <span class="btn-checkbox"></span>
      </label>
      <label style="float:right;" v-show="diamond == 0">88.00%</label>
      <label style="float:right;" v-show="diamond == 1">10.00%</label>
      <label style="float:right;" v-show="diamond == 2">2.00%</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterDiamonds",
  props: {
      filters : {},
      reload_function : {
        type: String,
        default: 'reload_list',
      },
  },
}
</script>