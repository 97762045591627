<template>
  <div>
		<section class="tf-explore tf-section bd-all-nfts-page">
			<div class="themesflat-container">
				<!-- mobile options -->
				<div class="bd-mobile" style="margin-top: 00px;">
					<h1>Explore NFTs</h1>
				</div>
				<div class="bd-mobile" style="margin-top: 20px; margin-bottom: 00px;">
					<div style="display: flex; justify-content: space-between;">
						<div class="seclect-box style-2 box-right" style="width: auto !important;">
							<div id="collection_filters" class="dropdown bd-dropdown">
								<a class="btn-selector nolink bd-dropdown-link" style="min-width: 130px;">{{ display_sorting() }}</a>
								<ul>
									<li @click="filters.sorting='price.asc';reload_list();"><span>Price, low to high</span></li>
									<li @click="filters.sorting='price.desc';reload_list();"><span>Price, high to low</span></li>
									<li @click="filters.sorting='listing_date.desc';reload_list();"><span>Recently listed</span></li>
									<li @click="filters.sorting='ranking.asc';reload_list();"><span>Ranking, low to high</span></li>
									<li @click="filters.sorting='ranking.desc';reload_list();"><span>Ranking, high to low</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- mobile filters -->
				<div class="row bd-mobile">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<div class="badge bd-tag active" style="display: block; margin-top: 30px; font-weight: 500;" @click="toggle_mobile_display_filters()">
							<i class="fas fa-filter" style="margin-right: 10px;"></i>
							Filters
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-xl-3 col-lg-3 col-md-12 bd-all-nfts-filters" :class="{display: mobile_display_filters === true}">

						<div id="side-bar" class="side-bar style-3">
              <div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Status</h4>
								</div>
								<div class="content-wg-category">
                  <form action="#">
                    <div>
                      <label> Listed
                        <input type="checkbox" v-model="filters.status.listed" @change="reload_list">
                        <span class="btn-checkbox"></span>
                      </label>
                    </div>
                    <div>
                      <label> Unlisted
                        <input type="checkbox" v-model="filters.status.unlisted" @change="reload_list">
                        <span class="btn-checkbox"></span>
                      </label>
                    </div>
                  </form>
								</div>
							</div>
              <div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Price</h4>
								</div>
								<div class="content-wg-category">
                  <form action="#">
						<div class=" bd-slider-container">
							<Slider v-model="filters.price.value" :min="filters.price.min" :max="filters.price.max" :step=0.01 :merge="filters.price.max / 5" :format="format_filter_price" @update="reload_list"></Slider>
						</div>
                  </form>
								</div>
							</div>
              <div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Diamonds</h4>
								</div>
								<div class="content-wg-category">
                  <form action="#">
                    <FilterDiamonds :filters="filters"></FilterDiamonds>
                  </form>
								</div>
							</div>

              <div class="widget mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Collections</h4>
								</div>
                <FilterCollection :filters="filters" :reload_function="'reload_list'"></FilterCollection>
							</div>
	
						</div>
					</div> 

					<div class="col-xl-9 col-lg-9 col-md-12 bd-nft-all-page-nft-zone">
						<div style="display: inline-flex;width: 100%; margin-bottom: 5%;" class="bd-desktop">
							<div class="seclect-box style-2 box-left" style="width: 100%;">
							<h1>Explore NFTs</h1>
							</div>
							<div class="seclect-box style-2 box-right" style="width: 100%;">
								<span class="bd-button-filter-collection-display" style="margin-left: 5%; cursor: pointer;" @click="$root.rotate($event,'reload_list_nfts_list');reload_list()">
									<i class="fas fa-sync-alt bd-button-icon bd-button-icon-rotation" style="font-size: 16px;"></i>
								</span>
								<span class="bd-button-filter-collection-display" @click="$root.number_element_per_line_cookie('three')" :class="{active: $root.number_element_per_line == 'three'}"  style="cursor: pointer;">
									<i class="fas fa-th-large bd-button-icon" style="font-size: 16px;"></i>
								</span>
								<span class="bd-button-filter-collection-display" @click="$root.number_element_per_line_cookie('four')" :class="{active: $root.number_element_per_line == 'four'}" style="cursor: pointer;">
									<i class="fas fa-th bd-button-icon" style="font-size: 16px;"></i>
								</span>
								<div id="collection_filters" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">{{ display_sorting() }}</a>
									<ul>
										<li @click="filters.sorting='price.asc';reload_list();"><span>Price, low to high</span></li>
										<li @click="filters.sorting='price.desc';reload_list();"><span>Price, high to low</span></li>
										<li @click="filters.sorting='listing_date.desc';reload_list();"><span>Recently listed</span></li>
										<li @click="filters.sorting='ranking.asc';reload_list();"><span>Ranking, low to high</span></li>
										<li @click="filters.sorting='ranking.desc';reload_list();"><span>Ranking, high to low</span></li>

									</ul>
								</div>
							</div>
						</div>
						<div class="box-epxlore" id="nfts-box" v-if="reload_activate !== true && nfts.length === 0">
							<NoResultsNft :nft_per_line="$root.number_element_per_line"></NoResultsNft>
							<NoResultsNft2 :nft_per_line="$root.number_element_per_line"></NoResultsNft2>
							<NoResultsNft2 :nft_per_line="$root.number_element_per_line"></NoResultsNft2>
							<NoResultsNft2 :nft_per_line="$root.number_element_per_line" v-if="$root.number_element_per_line == 'four'"></NoResultsNft2>
						</div>
            <div class="box-epxlore" id="nfts-box" v-if="!reload_activate || scroll_activate">
              <NftCard :nft="nft" :nft_per_line="$root.number_element_per_line" v-for="(nft, key) in nfts" :key="key"></NftCard>
              <div v-if="modulo >= 1" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
							<div v-if="modulo >= 2" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
							<div v-if="modulo >= 3" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
							<div v-if="modulo >= 4" class="sc-card-product bd-empty-nftcard" :class="{'two-per-line': $root.number_element_per_line == 'two','three-per-line': $root.number_element_per_line == 'three', 'four-per-line': $root.number_element_per_line == 'four'}"></div>
            </div>
            <div class="box-epxlore" id="nfts-box" v-if="reload_activate === true">
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line"></NoResultsNft3>
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line"></NoResultsNft3>
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line"></NoResultsNft3>
							<NoResultsNft3 :nft_per_line="$root.number_element_per_line" v-if="$root.number_element_per_line == 'four'"></NoResultsNft3>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import NftCard from '@/components/NftCard.vue';
import FilterDiamonds from '@/components/Filters/FilterDiamonds.vue';
import FilterCollection from '@/components/Filters/FilterCollection.vue';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import axios from "axios";
import NoResultsNft from '@/components/NoResults/NoResultsNft.vue';
import NoResultsNft2 from '@/components/NoResults/NoResultsNft2.vue';
import NoResultsNft3 from '@/components/NoResults/NoResultsNft3.vue';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
  name: "NftsList",
  components: {
    NftCard,
    Slider,
    FilterDiamonds,
    FilterCollection,
    NoResultsNft,
    NoResultsNft2,
    NoResultsNft3,
  },
  data: function(){

    return{
      filters: {
        price: {
          value: [0, 500],
          min: 0,
          max: 500,
        },
        status: {
          listed: true,
          unlisted: false,
        },
        diamonds: {
          0: false,
          1: false,
          2: false,
        },
        sorting: 'price.asc',
        number_per_page: 15,
        collection: {},
      },
      nfts:{},
      reload_activate:false,
      scroll_activate: false,
      count_elements: 0,
		mobile_display_filters: false,
    }
  },
  methods:{
    reload_list: function(e,initialize = false,scroll = false){

      var $this = this;

      this.reload_activate = true;

      var filters = $this.filters;

      if(initialize === true)
        filters['initialize'] = true;

      this.scroll_activate = scroll;

      if(scroll === false)
        delete filters.number_per_page;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/nft/reload_list',filters, config_axios).then(function(elements) {

        if(scroll === true)
          $this.nfts = $this.nfts.concat(elements.data.elements);
        else {
          $this.nfts = elements.data.elements;
          filters.number_per_page = 15;
        }

        if(initialize === true) {
          filters.price = elements.data.filters.price;

          delete filters['initialize'];

        }

        $this.count_elements = elements.data.count_elements;

        $this.reload_activate = false;
        $this.scroll_activate = false;

      });

    },
    display_sorting: function() {

      if(this.filters.sorting == 'price.asc')
        return 'Price, low to high';

      if(this.filters.sorting == 'price.desc')
        return 'Price, high to low';

      if(this.filters.sorting == 'listing_date.desc')
        return 'Recently listed';

      if(this.filters.sorting == 'ranking.asc')
        return 'Ranking, low to high';

      if(this.filters.sorting == 'ranking.desc')
        return 'Ranking, high to low';
    },
    format_filter_price: function (value) {
      return '◎ '+Math.round((value + Number.EPSILON) * 100) / 100;
    },
	toggle_mobile_display_filters: function() {

		if(this.mobile_display_filters === true)
			this.mobile_display_filters = false;
		else
			this.mobile_display_filters = true;
	},
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.getElementById('nfts-box').offsetHeight

        if (bottomOfWindow && this.reload_activate === false && this.count_elements > this.filters.number_per_page) {
          this.reload_list(false, false, true);
          this.filters.number_per_page += 15;
        }
      }
    },
  },
  created: function() {

    var $this = this;

    $this.reload_list(false,true);
  },
  mounted () {
    window.scrollTo(0, 0)
    this.scroll();

    var component_vue = this;

    component_vue.$root.$on('change_buy_nft',function(nft_id){

      component_vue.reload_list(false,true);

		});
  },
  computed: {
    modulo: function () {

      var nft_per_line = 0;

      if (this.$root.number_element_per_line == 'four')
        nft_per_line = 4;
      else
        nft_per_line = 3;

      if (this.nfts == undefined)
        return 0;

      if (this.nfts == undefined)
        return 0;

      if (this.nfts.length == 0)
        return 0;

      return parseInt(this.nfts.length % nft_per_line);


    }
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>