<template>

  <div class="content-wg-category">
    <div class="collection-filter">
      <input class="collection-filter-input" type="text" placeholder="Collection name" @keyup="search_collection()" v-model="search_value"/>
      <div class="collection-filter-result" v-if="loaded === true">
        <div v-for="(collection, collection_key) in filters.collection" v-show="(collection_filters == undefined || collection_filters[collection_key] != undefined) &&filters.collection[collection_key].select === true" :key="'active_' + collection_key" @click="filters.collection[collection_key].select = false;$parent[reload_function]();$forceUpdate()">
          <i class="collection-filter-logo collection-filter-logo-active">
            <span class="fas fa-check" style="color: white"></span>
          </i>
          {{ collection.name }}
        </div>
        <div v-for="(collection, collection_key) in searched_collection" v-show="(collection_filters == undefined || collection_filters[collection_key] != undefined) && filters.collection[collection_key].select === false" :key="collection_key" @click="filters.collection[collection_key].select = true;$parent[reload_function]();$forceUpdate()">
          <i class="collection-filter-logo">
            <img :src="route_bo + '/storage/' + collection.logo"/>
          </i>
          {{ collection.name }}
        </div>
      </div>

      <div class="collection-filter-result" v-else>
        <div>
          <i class="collection-filter-logo collection-filter-logo-active">
            <img src="/assets/images/bluediamonds/loader-light.gif" class="bd-light" />
            <img src="/assets/images/bluediamonds/loader-dark.gif" class="bd-dark" />
          </i>
          <span class="badge badge-success bd-noresult-buy" style="width: 100%">&nbsp;</span>
        </div>
        <div>
          <i class="collection-filter-logo collection-filter-logo-active">
            <img src="/assets/images/bluediamonds/loader-light.gif" class="bd-light" />
            <img src="/assets/images/bluediamonds/loader-dark.gif" class="bd-dark" />
          </i>
          <span class="badge badge-success bd-noresult-buy" style="width: 100%">&nbsp;</span>
        </div>
        <div>
          <i class="collection-filter-logo collection-filter-logo-active">
            <img src="/assets/images/bluediamonds/loader-light.gif" class="bd-light" />
            <img src="/assets/images/bluediamonds/loader-dark.gif" class="bd-dark" />
          </i>
          <span class="badge badge-success bd-noresult-buy" style="width: 100%">&nbsp;</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';

let config_axios = {
  headers: {
    'Content-Type': 'application/json;',
  }
}

export default {
  name: "FilterCollection",
  data : function() {
    return {
      search_value : '',
      searched_collection : {},

      route_server: process.env.VUE_APP_ROOT_API,
      route_bo: process.env.VUE_APP_ROOT_BO,
    }
  },
  props : {

    filters : {},
    reload_function : {
      type: String,
      default: '$forceUpdate',
    },
    collection_filters : {},
    loaded : {
      type: Boolean,
      default: true,
    },

  },
  methods : {

    search_collection : function (){

      var $this = this

      $this.searched_collection = {};

      if($this.search_value.length == 0) {

        $this.searched_collection = JSON.parse(JSON.stringify($this.filters.collection));
        return;

      }

      for (const [key, value] of Object.entries($this.filters.collection)) {

        if(value.name.toUpperCase().includes($this.search_value.toUpperCase())) {
          $this.searched_collection[key] = value;
        }

      }

    },

    load_collection : function(){

      var $this = this;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/collection/get_for_filter', config_axios).then(function(elements) {

        setTimeout(function (){

          $this.searched_collection = JSON.parse(JSON.stringify(elements.data));
          $this.filters.collection = JSON.parse(JSON.stringify(elements.data));

        },300)

      });

    }

  },
  created() {

    var $this = this;

    $this.load_collection();

  },
  mounted() {

    var $this = this;

    $this.load_collection();

  },
}
</script>

<style scoped>

  .collection-filter{

    border-radius: 5px;
    background-color: var(--background2);

  }

  .collection-filter-input{

    background-color: transparent;

  }

  .collection-filter-result{

    padding: 5px;
    overflow-y: scroll;
    height: 120px;

  }

  /* width */
  .collection-filter-result::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .collection-filter-result::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  .collection-filter-result::-webkit-scrollbar-thumb {
    background: var(--background4);
    border-radius: 10px;
  }

  .collection-filter-result > div{

    margin-top: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;

  }

  .collection-filter-result > div:hover{

    cursor: pointer;

  }

  .collection-filter-logo{

    display: block;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin-right: 10px;

  }

  .collection-filter-logo-active{

    background-color: var(--primary);
    border-radius: 5px;

  }

  .collection-filter-logo > img{

    min-width: 105%;
    max-width: 105%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .collection-filter-logo > span{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

</style>