<template>
	<span>
		<span v-if="collection_page === false" @click="like_nft(nft)" :class="'wishlist-button heart '+(liked_by_user == true ? 'active' : '')">
			<span class="number-like"> {{ nft.liked | format_number }}</span>
		</span>
		<div v-if="collection_page === true" @click="like_nft(nft)" class="bd-nftcard-like">
			<i :class="(liked_by_user == true ? 'fas' : 'far')+' fa-heart '+(liked_by_user == true ? 'active' : '')"></i> {{ nft.liked | format_number }}
		</div>
	</span>
</template>

<script>

import axios from 'axios';

let config_axios = {
  headers: {
    'Content-Type': 'application/json;',
  }
}

import toastr from 'toastr';

export default {
  name: 'LikeButtonNft',
  components: {
	
  },
  props: {
    nft: {},
    collection_page: {
			type: Boolean,
			default: false
		}
	},
  data:function(){
      return{
        like_action_request: false,
      }
  },
  created: function() {

		var component_vue = this;

		component_vue.$root.$on('connection_wallet_accept',() => {

			
		});
  },
  computed: {

		liked_by_user: function (){
			
			var component_vue = this;

			var liked = false;

			component_vue.$root.nfts_liked_by_user.forEach(function(nft_id){

				if(nft_id === component_vue.nft.id)
					liked = true;
			});

			return liked;
		},
  },
  methods:{

		like_nft: function (nft){

      var component_vue = this;

      if(component_vue.$root.connected == 0)
        component_vue.$root.$emit('show_modal',['connect_wallet',true]);

      else
        component_vue.request_like_nft(nft);

    },

    request_like_nft: function(nft){

			var component_vue = this;

			if(component_vue.liked_by_user === true){

				component_vue.$root.nfts_liked_by_user.forEach(function(nft_id, key){

					if(nft_id === component_vue.nft.id){

						component_vue.nft.liked -= 1;
						component_vue.$root.nfts_liked_by_user.splice(key, 1);
					}
				});
			}
			else{

				component_vue.nft.liked += 1;
				component_vue.$root.nfts_liked_by_user.push(component_vue.nft.id);
			}

			var config = config_axios;

			config.nft_id = nft.id
			config.wallet_address = component_vue.$root.wallet_address

			if(component_vue.like_action_request !== false)
				component_vue.like_action_request.abort();

			component_vue.like_action_request = new AbortController();

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/nft/like_nft', config,{signal: component_vue.like_action_request.signal}).then(function(){

				component_vue.like_action_request = false;
		});	
  },
},
}
</script>

